import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227jNhB991ewARZwgNCQFMebyFhg0U9o+5CiCIqROLKZUKRK0rKdIv9ekJRlSb6svUifbMztnDMz5vj7G24LDSUa8necl7N/tq9T8u+IkEKr0n8hRFWQc7tNSTQfEfIxIsSqoSsOro/R98OCD+cLEmI1SFMoXabhqwCLf45p8uWWmBwEjqPJ0+z2B+CnykS7IvHtGY4zX/Ow9E8xPCb1Ezh+HfbxeLE4ir4cNusU8Bm8x8vwokvBOsSO4j1dgvd8hb7n8/rgMrwL9T339U12KOhzKmW45UqmpOAbZC6KS4O22bAM8reFVivJaK6E0impQY8phaLgEmnHXSoGIgR5rHfKJcPNIKGxhmgfB5KXEBjsf+jxQ1QaUii9Bs3MvEd74jKoVDII6OQ72yC2VBkXIfAHUuqECtii7mty2S3ZTuXikuYxbioB25QUAjdeq+ALSbnF0qQkR2lRO/PrylhebGmupEVpu67Lutgl9hcDCw3zl9ChLqqjQlF6rhUwxuWCZspaVaYEZT02UCAFjUC9ksZ3R5Ko2hxFKlZCUJNrRPntxuoV3gTUpnhKIvILLyulLUjbL3DFJJvYAhj+7h6kP1Q1zNm/6mF78lXGc5rhO0c9jibT5I5EdySaPDzekbi/erTgQrhuYtpbuqBwV/j+hZzjM6kthdzyOqipOa6p/xH6FaESStyTzI6pM4Iz/NU3/JS4rySaJPeGIBj8HAkd0KsU5CcV/MYXS3tKwNP/I8BjXsWfueJpOgxSgo3bId2eUjH7vB07TyE/SeHx2kaeB2IngeBaoHZAC1+StsOLUjLvGeKhIQmGNWd2uXvz2mzfzyW6SQ99odcll/S4Pwl+2LT+HETuDiOrl4SS+8S/biGkQd9HrAndvX+EZGpDDX/3T1umNHMXQ/nXvVDSOhcOXmtvz8DgbRu1blhMI38ohAvbMYsns3n3/XTIJJlWHmN/cTQKcKt+3ZVWNWoBW1qBxO619qqWwNR6kFupyuU0zibWq9bA+MqkJE4CNbWyTsf+8T59H85tSBxF9fpgTZx1ebArrbUztja/O+02cMA96u3rcnDUITNKrKxvsVVVSpJGqg5V3WR+6kjz8Afv3L4s6exwXWZH12X6EOTqBZftLQ9T6WC+esy10oxmGuEtJf6DOov/zS25RWoqyDEllUa61lD5udaoC6HW3tDP2s3YS/x2U4AwePNy8CeJu38zFGuU1gzXo5863IxhLqysmo8+/gPtuj2YHQ4AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyjh';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjg';
export var modalContentWrapper = '_1cm6qyjf';
export var modalDescription = '_1cm6qyjj';
export var modalHeader = '_1cm6qyji';
export var modalOverlay = '_1cm6qyje';
export var modalVTScope = '_1cm6qyj3';
export var widthVar = 'var(--_1cm6qyj0)';