import "../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62UwXKbMBCG734KHeuDMuASN1UepiPEAtsKSSOtgbSTd+9IOA1g4zgzPSGJXe3/7b/w8CMfj23e9xn7s2OsBWxaEizPsr593jE2YEXttB/ivsLgtHwRrNYwxoOfp0BYv3BlDYEhwRQYAh9fSY2N4UjQhflxbQ3xgL9BsF76L5zLukYDPJ2XMsA+RjkbkNAawTxoSdjD8+519/AmN09yldXWC4amBY+0CDikgPdbZBmsPhHEu8k6wbK40lDTeekn8uwq5QbKBXxwUgEvgQYAkzBkVaFpBMuPbmT5IXPjQubXJPOinrL61BneSCdYsUop5uiLDhKMxJ3HTvqoSVu//7DhoXuPGc7uP2apC+m6CpT1cuqhsQYWUEVkelrpe/wYKTZjkXPcZNJofq1Z7tV5D7g6+RCLOouTqzNV366TxCev0IOayk1kN8akk75Bw9PYFesJeEpF5iHTkPw7Ky2R7QTLD6vM75uZn5Ds7XD25OC2R31WVaaqn/LhXl9vWjWTUP4nXy7Yr/8u3gwo1pOuruvYqDZVOjt0k3cM+622pR4HUNZU84/89S/nLq07yAUAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accidentHandling = '_1x6h1vv9';
export var affineLogo = '_1x6h1vv1';
export var centerContent = '_1x6h1vv7';
export var docFooter = '_1x6h1vvb';
export var docFooterText = '_1x6h1vvc';
export var editSettingsLink = '_1x6h1vva';
export var prompt = '_1x6h1vv8';
export var promptLink = '_1x6h1vv6';
export var promptLinks = '_1x6h1vv5';
export var root = '_1x6h1vv0';
export var topNav = '_1x6h1vv2';
export var topNavLink = '_1x6h1vv4';
export var topNavLinks = '_1x6h1vv3';